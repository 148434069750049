/** @format */

import React from "react";
import { langAction } from "../../store/action";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import './initStyle.scss';

const SelectLang = () => {
  const [activeHome, setActiveHome] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const handleChangeLang = (lang) => {
    if (lang === "en") {
      dispatch(langAction("en"));
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      i18n.changeLanguage("en");
    } else {
      dispatch(langAction("th"));
      document.getElementsByTagName("html")[0].setAttribute("lang", "th");
      i18n.changeLanguage("th");
    }
    setActiveHome(true);
  };
  return (
    <>
      {activeHome && <Redirect exact to={"/home"} />}
      <div className="select-lang">
        <h1>Choose Language!</h1>
        <ul>
          <li onClick={() => handleChangeLang("en")}>English</li>
          <li onClick={() => handleChangeLang("th")}> ไทย</li>
        </ul>
      </div>
    </>
  );
};

export { SelectLang };
