/** @format */

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  ErrorStyle1,
  HomeStyle1,
  LandingStyle1,
  LandingStyle2,
  ContactStyle1,
  SelectLang,
} from "./pages";
import Projects from "./pages/projects/Projects";
import Skill from "./pages/skills/Skills";

const routes = [
  {
    path: "/home",
    component: () => <HomeStyle1 />,
    exact: true,
  },
  {
    path: "/contact",
    component: () => <ContactStyle1 />,
    exact: true,
  },
  {
    path: "/projects",
    component: () => <Projects />,
    exact: true,
  },
  {
    path: "/",
    component: () => <LandingStyle2 />,
    exact: true,
  },
  {
    path: "/select-language",
    component: () => <SelectLang />,
    exact: true,
  },
  {
    path: "/skills",
    component: () => <Skill />,
    exact: true,
  },
];
function RouterStack() {
  return (
    <Router>
      <Switch>
        {routes.map((route, key) => {
          if (route.from || route.to) {
            return (
              <Redirect
                key={key}
                exact={route.exact}
                from={route.from}
                to={route.to}
              />
            );
          }
          return (
            <Route key={key} path={route.path} exact={route.exact}>
              {route.component}
            </Route>
          );
        })}
        <Route path="*" component={ErrorStyle1} />
      </Switch>
    </Router>
  );
}

export default RouterStack;
