import React, { useState, useEffect } from "react";
import { MainLogo } from "../../config";
import { NavLink, Link } from "react-router-dom";
import { useWindowSize } from "../../components";
import { langAction } from "../../store/action";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const HeaderStyle1 = () => {
  let lang = useSelector((state) => state.langReducer);
  const { t, i18n } = useTranslation();
  // let [lang,setLang] = useState(lang)
  let [atHamberger, setAtHamberger] = useState(true);
  let [activeBG, setActiveBg] = useState(false);
  let windonwSize = useWindowSize().width < 992;
  const dispatch = useDispatch();

  const handleHamberger = () => {
    setAtHamberger(!atHamberger);
    if (window.scrollY > 300) {
      setActiveBg(true);
    }
  };
  const onScrollHeader = (event) => {
    if (window.scrollY > 100) {
      setAtHamberger(false);

      // console.log(parseInt(window.scrollY))
    } else {
      // windonwSize?setAtHamberger(true):setAtHamberger(false);
      setActiveBg(false);
      setAtHamberger(true);
    }
  };
  const handleChangeLang = () => {
    if (lang === "en") {
      dispatch(langAction("th"));
      document.getElementsByTagName("html")[0].setAttribute("lang", "th");
      document.getElementsByTagName("html")[0].setAttribute("theme", "lg");
      i18n.changeLanguage("th");
    } else {
      dispatch(langAction("en"));
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      document.getElementsByTagName("html")[0].setAttribute("theme", "dk");
      i18n.changeLanguage("en");
    }
  };
  useEffect(() => {
    if (lang === "en") {
      document.getElementsByTagName("html")[0].setAttribute("theme", "dk");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("theme", "lg");
    }
    i18n.changeLanguage(lang);
    window.addEventListener("scroll", onScrollHeader);
    return () => window.removeEventListener("scroll", onScrollHeader);
  }, [i18n, lang]);
  return (
    <>
      <header>
        <div className="header">
          <div className="header-fixed">
            <div className="header-inner">
              <div className="header-logo">
                <div
                  className={`header-logo__flame ${
                    atHamberger ? "active" : ""
                  }`}
                >
                  <Link to={"/home"}>
                    <img src={MainLogo} alt="Logo" />
                  </Link>
                </div>
              </div>
              <div
                className={`header-navbar ${
                  activeBG || windonwSize ? `onScroll-bg` : ""
                }`}
              >
                <ul className={`${atHamberger ? "active" : "unActive"}`}>
                  <li>
                    <NavLink to={"/home"}>{t("header.home")}</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to={"/skills"}>{t("header.about")}</NavLink>
                  </li> */}
                  <li>
                    <NavLink to={"/projects"}>{t("header.project")}</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to={"/journey"}>{t("header.journey")}</NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink to={"/blog"}>{t("header.blog")}</NavLink>
                  </li> */}
                  <li>
                    <NavLink to={"/contact"}>{t("header.contact")}</NavLink>
                  </li>
                </ul>
              </div>
              <div className="language">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={handleChangeLang}
                    checked={lang === "th" ? true : false}
                  />
                  <span className="slider round"></span>
                  <span className="lang-text">{lang}</span>
                </label>
              </div>
              <div className="header-hamberger">
                <div
                  className={`hamberger-box ${atHamberger ? `active` : ``}`}
                  onClick={handleHamberger}
                  title="Menu"
                >
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export { HeaderStyle1 };
