import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./scss/main.scss";
import RouterStack from "./router";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import { allReducers } from "./store/reducer";
import { Provider } from "react-redux";
import Modal from "react-modal";
import axios from "axios";
import { API } from "./config";
import reduxThunk from "redux-thunk";

import "./i18n";

Modal.setAppElement("#root");
axios.defaults.baseURL = API;
let store = createStore(allReducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Suspense fallback={<div>Loading...</div>}>
        <RouterStack />
      </Suspense>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
