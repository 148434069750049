import { reduxType } from "../../config";

const getProjects = (state = "null", action) => {
  switch (action.type) {
    case reduxType.GETPROJECTS:
      return (state = action.playload);
    default:
      return state;
  }
};
export default getProjects;
