/** @format */

import React from "react";
import { HeaderStyle1 } from "../../components";
import { Brain } from "./Brain";
import { BubbleCard } from "./BubbleCard";

// for test
import TestImg from "../../assets/imgs/background/bg-hero-light.jpg";

class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.animateBubble = React.createRef();
    this.state = {
      showHeader: false,
      circleSkill: 360 / 5,
      data: {
        labels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        datasets: [
          {
            label: "jquery",
            data: [5],
            borderColor: "red",
            backgroundColor: "pink",
          },
        ],
      },
      skills: [
        { name: "PHP", level: 5, img: TestImg },
        { name: "HTML", level: 8, img: TestImg },
        { name: "CSS", level: 8, img: TestImg },
        { name: "Jquery", level: 7, img: TestImg },
        { name: "Javascript", level: 7, img: TestImg },
      ],
    };
  }

  render() {
    return (
      <>
        <HeaderStyle1 />
        <section className="section-container">
          <div className="skills">
           
          </div>
        </section>
      </>
    );
  }
}

export default Skills;
