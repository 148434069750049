import { reduxType } from "../../config";
const changeLanguage = (state = "en", action) => {
  switch (action.type) {
    case reduxType.CHANGELANG:
      return (state = action.playload);
    default:
      return state;
  }
};
export default changeLanguage;
