/** @format */

import React from "react";
import { OrangeLine } from "../utils";
import { Email } from "../../config";

const HeroStyle1 = ({ profile, t }) => {
  return (
    <>
      <div className="hero-stage">
        <div className="dot-rotate">
          <span className="green-dot dot-1"></span>
          <span className="green-dot dot-2"></span>
        </div>

        <div className="hero-content">
          <div className="hero_name">
            <p>
              {" "}
              <span>p</span>ai
              <br />
              sa<span>p</span>oo
            </p>
          </div>
          <div className="hero_profile-pic">
            <img src={profile} alt="Avatar" />
          </div>
          <div className="hero_detail">
            <OrangeLine text={"Front-end Dev UI"} />
            <h5>
              {t("hero.local.preText")}, {t("hero.local.country")},<br />
              {t("hero.subText1")}
              <br />
              {t("hero.subText2")}
            </h5>
            <a href={`mailto:${Email}`} className="btn btn-primary">
              {t("hero.subText3")}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export { HeroStyle1 };
