/** @format */

import { reduxType } from "../../config";
const langAction = (lang) => {
  return {
    type: reduxType.CHANGELANG,
    playload: lang,
  };
};

export { langAction };
