/** @format */

import React from "react";
import { HeaderStyle1, HeroStyle1, OrangeLine } from "../../components";
import {
  ProfilePic1,
  MailTo,
  Email,
  Phone,
  CV,
  Github,
  Linkedin,
} from "../../config";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "../../components";
import { Skill } from './skill';

const HomeStyle1 = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderStyle1 />
      <HeroStyle1 profile={ProfilePic1} t={t} />
      <div className="section-container">
        <div className="sub-about">
          <div className="sub-about_left">
            <OrangeLine text={t("aboutMe.title")} />
            <h5>{t("aboutMe.subTitle")}</h5>
            <p>{t("aboutMe.text1")}</p>
            <div className="experience">
              <span>3</span>
              <span>{t("aboutMe.experience")}</span>
            </div>
          </div>
          <div className="sub-about_right">
            <h1 className="text-title-extra">{t("aboutMe.text2")}</h1>
            <h3>{t("aboutMe.subText2")}</h3>
            <a href={MailTo} className="text-link">
              <span> {Email}</span>
            </a>
            <div className="footer-social">
              <a href={"tel:" + Phone} className="text-link">
                <FontAwesomeIcon icon={["fas", "mobile-alt"]} />
              </a>
              <a href={MailTo} className="text-link">
                <FontAwesomeIcon icon={["fas", "envelope"]} />
              </a>
              <a
                href={Github}
                target="_blank"
                rel="noopener noreferrer"
                className="text-link"
              >
                <FontAwesomeIcon icon={["fab", "github"]} />{" "}
              </a>
              <a
                href={Linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="text-link"
              >
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
              </a>
              <a href={CV} className="text-link" download title="portfolio">
                <FontAwesomeIcon icon={["fas", "file-alt"]} />
              </a>
            </div>
          </div>
        </div>
        <Skill />
      </div>
    </>
  );
};

export { HomeStyle1 };
