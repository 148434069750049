/** @format */

import React from "react";

const OrangeLine = ({ text }) => {
  return (
    <>
      <div className="sub-title">
        <h3>{text}</h3>
        <span></span>
      </div>
    </>
  );
};

export { OrangeLine };
