/** @format */

import React, { useEffect, useState } from "react";
import { HeaderStyle1 } from "../../components";

const ContactStyle1 = () => {
  const [mousePositionX, setMousePositionX] = useState(null);
  const [mousePositionY, setMousePositionY] = useState(null);
  const handleFineMe = (event) => {
    setMousePositionX(event.clientX);
    setMousePositionY(event.clientY);
  };
  useEffect(() => {
    window.addEventListener("mousemove", handleFineMe);
    return () => window.removeEventListener("mousemove", handleFineMe);
  }, []);
  return (
    <>
      <section style={{ cursor: "none" }}>
        <HeaderStyle1 />
        <section className="section-container">
          <div className="contact">
            <div
              className="mouse-style"
              style={{ top: mousePositionY, left: mousePositionX }}
            ></div>
            <h1 className="page-title">Find Me</h1>
            <div
              className="contact-detail"
              style={{
                clipPath: `circle(100px at ${mousePositionX}px ${mousePositionY}px)`,
              }}
            >
              <img
                src={require("../../assets/imgs/profile/profile-1.png")}
                alt=""
                className="profile1"
              />
              <img
                src={require("../../assets/imgs/profile/profile_port-web.png")}
                alt=""
                className="profile2"
              />
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export { ContactStyle1 };
