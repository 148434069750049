/** @format */

import React from "react";
import { HeaderStyle1 } from "../../components";
import { CardItemList } from "./CardItemList";
import Modal from "react-modal";
import { adminSite } from "../../config";
import { FontAwesomeIcon } from "../../components";

import { connect } from "react-redux";
import { getProjects } from "../../store/action";

// import jsonData from '../../store/data/dataProject.json';

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetail: [],
      isOpen: false,
    };
    this.ModelOpen = this.ModelOpen.bind(this);
  }

  ModelOpen = (detail) => {
    this.setState({ isOpen: true });
    this.setState({ projectDetail: detail });
  };
  componentDidMount() {
    this.props.getProjects();
  }

  render() {
    return (
      <>
        <HeaderStyle1 />
        <section className="section-container">
          <div className="project-list">
            <h1 className="page-title">Projects</h1>
            <div className={`list-wrapper`}>
              {typeof this.props.jsonData != "undefined"
                ? this.props.jsonData.map((data, index) => (
                    <CardItemList
                      data={data}
                      ModelOpen={this.ModelOpen}
                      key={index}
                    />
                  ))
                : ""}
            </div>
          </div>
        </section>
        {this.state.isOpen && (
          <Modal
            isOpen={this.state.isOpen}
            onRequestClose={() => this.setState({ isOpen: false })}
            contentLabel="Example Modal"
            className={"modal "}
          >
            <div className="modal-project-detail">
              <i
                className="icon-close"
                onClick={() => this.setState({ isOpen: false })}
              >
                <FontAwesomeIcon icon={["far", "window-close"]} />
              </i>
              <div className="detail-image-box">
                {JSON.parse(this.state.projectDetail.full_img).map(
                  (item, index) => (
                    <img key={index} src={adminSite + item} alt="Detail" />
                  )
                )}
              </div>
              <div className="detail-text-box">
                <h2 className="text-title2">
                  {this.state.projectDetail.title}
                </h2>
                <p className="text-body1">{this.state.projectDetail.detail}</p>
                <h5>
                  My responsibility : {this.state.projectDetail.responsibility}
                </h5>
                <div className="detail-text-box__footer">
                  <p className="dev-tool">
                    Dev Tool : {this.state.projectDetail.dev_tool}
                  </p>
                  {this.state.projectDetail.url !== "" ? (
                    <a
                      rel="noopener noreferrer"
                      href={this.state.projectDetail.url}
                      target="_blank"
                      title="Live"
                    >
                      <i className="icon">
                        <FontAwesomeIcon icon={["fas", "globe"]} />
                      </i>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log(state.getProjects.projects)
  return {
    jsonData: state.getProjects.projects,
  };
}

export default connect(mapStateToProps, { getProjects })(Projects);
