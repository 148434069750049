/** @format */

const MainLogo = require("./assets/imgs/logo/logo.svg");
const ProfilePic1 = require("./assets/imgs/profile/profile_port-web2.png");
const ProfilePic2 = require("./assets/imgs/profile/profile_port-web.png");
const MailTo = "mailto:paisapoo@gmail.com";
const Email = "paisapoo@gmail.com";
const Phone = "+66922584838";
const Instagram = "https://www.instagram.com/sasirinpai/";
const Linkedin = "https://www.linkedin.com/in/pai-sapoo-4b0411155/";
const CV = require("./assets/docs/paisapoocv.pdf");
const Github = "https://github.com/paisapoo/";
const adminSite = "http://dashboard.paisapoo.me/";
const API = "http://backendportpolio.paisapoo.me/api/";

const reduxType = {
  CHANGELANG: "CHANGELANG",
  GETPROJECTS: "GETPROJECTS",
};

export {
  MainLogo,
  ProfilePic1,
  ProfilePic2,
  MailTo,
  Email,
  Phone,
  Instagram,
  Linkedin,
  CV,
  Github,
  adminSite,
  API,
  reduxType,
};
