/** @format */

import React from "react";
import { HeaderStyle1 } from "../../components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const ErrorStyle1 = () => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderStyle1 />
      <section className="section-container">
        <div className="error-style1">
          <h1>{t("inprogress")}</h1>
          <Link to={"/home"} className="btn btn-primary">
            {t("btn.backHome")}
          </Link>
        </div>
      </section>
    </>
  );
};

export { ErrorStyle1 };
