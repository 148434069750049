/** @format */

import { combineReducers } from "redux";
import changeLanguage from "./langReducer";
import getProjects from "./ProjectReducer";

const allReducers = combineReducers({
  langReducer: changeLanguage,
  getProjects: getProjects,
});

export { allReducers };
