/** @format */

import React, { useState } from "react";
import { adminSite } from "../../config";

const CardItemList = ({ data, ModelOpen }) => {
  const [read, setRead] = useState(false);
  const itemRead = () => {
    setRead(true);
  };

  return (
    <>
      {/* {console.log(adminSite)} */}
      <div className={`card__item-list ${read ? `already-read` : ``}`}>
        <div className="card__preview-img">
          <img src={adminSite + data.preview_img} alt="Preview" />
        </div>
        <h3 className="title">{data.title}</h3>
        <p className="caption">Dev Tool : {data.dev_tool}</p>
        <button
          className={`btn btn-primary ${read ? `linked` : ``}`}
          onClick={() => {
            ModelOpen(data);
            itemRead();
          }}
        >
          {read ? "see again" : "See More..."}
        </button>
      </div>
    </>
  );
};

export { CardItemList };
