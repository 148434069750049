/** @format */

import axios from "axios";
import { reduxType } from "../../config";
const getProjects = () => {
  return async(dispatch) => {
    await axios.get("/projects_api.php").then((res) => {
      dispatch({ type: reduxType.GETPROJECTS, playload: res.data });
    });
  };
};

export { getProjects };
