import React from 'react'
import { OrangeLine } from "../../components";


const Skill = () => {
   let data = [
    { name: "PHP", level: 5},
    { name: "HTML", level: 8},
    { name: "CSS", level: 8 },
    { name: "Jquery", level: 7},
    { name: "Wordpress", level: 5},
    { name: "Typo3", level: 4},
    { name: "Javascript", level: 7},
    { name: "React", level: 5},
    { name: "Figma", level: 7},
    
  ]
    return (
        <>
        <div className="skill-box">
            <OrangeLine text={'Skills'} />
            <div className="skill-inner">
                {data.map((item,index)=>{
                    return (
                        <div className="skill-item" key={index} data-level={item.level}>
                            <span className="skill-title">{item.name}</span>
                            <span className="skill-level" >{item.level}/10</span>
                        </div>
                    )
                })}
            </div>
        </div>
        </>
    )
}

export {Skill}