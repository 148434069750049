/** @format */

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Brain } from "../skills/Brain"; 

import './initStyle.scss'

const LandingStyle1 = () => {
  const [activeHome, setActiveHome] = useState(false);
  useEffect(() => {
    let time = setTimeout(() => {
      setActiveHome(true);
    }, 10000);
    return () => time;
  }, []);
  return (
    <>
      {activeHome && <Redirect to={"/select-language"} />}
      <div className="landing-page">
        <h1>Hello, Welcome To</h1>
        <h1>paipapoo.com</h1>
      </div>
    </>
  );
};

export { LandingStyle1 };


const LandingStyle2 = () => {
  const [activeHome, setActiveHome] = useState(false);
  useEffect(() => {
    let time = setTimeout(() => {
      setActiveHome(true);
    }, 10000);
    return () => time;
  }, []);
  return (
    <>
      {activeHome && <Redirect to={"/select-language"} />}
      <div className="brain-box landing">
        <Brain />
      </div>
    </>
  );
};

export { LandingStyle2 };